import cn from 'classnames'
import s from './index.module.css'

function NextArrow({ isDark, isDisabled = false, className }) {
  return (
    <div
      className={cn(
        s.arrow,
        className,
        'flex h-[58px] w-[58px] items-center justify-center rounded-full bg-opacity-20',
        isDark ? 'bg-[#fff]' : 'bg-[#131313]',
        isDisabled
          ? 'cursor-not-allowed !opacity-0'
          : 'cursor-pointer transition-all duration-[0.4s] [&:hover]:bg-opacity-40'
      )}
    >
      <svg
        className={isDisabled ? 'opacity-40' : ''}
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <path
          d="M10.8417 21.8248C10.3861 21.3692 10.3861 20.6305 10.8417 20.1749L17.0168 13.9998L10.8417 7.8248C10.3861 7.36919 10.3861 6.63049 10.8417 6.17488C11.2973 5.71927 12.036 5.71927 12.4916 6.17488L19.4916 13.1749C19.9472 13.6305 19.9472 14.3692 19.4916 14.8248L12.4916 21.8248C12.036 22.2804 11.2973 22.2804 10.8417 21.8248Z"
          fill="white"
        />
      </svg>
    </div>
  )
}

function PrevArrow({ isDark, isDisabled = false, className }) {
  return (
    <div
      className={cn(
        s.arrow,
        className,
        'flex h-[58px] w-[58px] items-center justify-center rounded-full bg-opacity-20',
        isDark ? 'bg-[#fff]' : 'bg-[#131313]',
        isDisabled
          ? 'cursor-not-allowed !opacity-0'
          : 'cursor-pointer transition-all duration-[0.4s] [&:hover]:bg-opacity-40'
      )}
    >
      <svg
        className={isDisabled ? 'opacity-40' : ''}
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <path
          d="M17.1583 21.8248C17.6139 21.3692 17.6139 20.6305 17.1583 20.1749L10.9832 13.9998L17.1583 7.8248C17.6139 7.36919 17.6139 6.63049 17.1583 6.17488C16.7027 5.71927 15.964 5.71927 15.5084 6.17488L8.50837 13.1749C8.05276 13.6305 8.05276 14.3692 8.50838 14.8248L15.5084 21.8248C15.964 22.2804 16.7027 22.2804 17.1583 21.8248Z"
          fill="white"
        />
      </svg>
    </div>
  )
}

function Arrow({
  isStart,
  isEnd,
  prevClass = 'custom-swiper-button-prev',
  nextClass = 'custom-swiper-button-next',
  className = '',
}) {
  const containerClass = cn(
    'mt-[24px] flex items-center justify-center gap-[16px] md:hidden',
    className
  )
  const prevArrowClass = cn(prevClass, 'h-[58px] w-[58px] cursor-pointer')
  const nextArrowClass = cn(nextClass, 'h-[58px] w-[58px] cursor-pointer')
  const prevArrowStroke = isStart ? '#C4C4C6' : 'black'
  const nextArrowStroke = isEnd ? '#C4C4C6' : 'black'

  return (
    <div className={containerClass}>
      <div className={prevArrowClass}>
        <PrevArrow
          isDisabled={isStart}
          gs={{
            circleStroke: prevArrowStroke,
            pathStroke: prevArrowStroke,
          }}
        ></PrevArrow>
      </div>
      <div className={nextArrowClass}>
        <NextArrow
          isDisabled={isEnd}
          gs={{
            circleStroke: nextArrowStroke,
            pathStroke: nextArrowStroke,
          }}
        ></NextArrow>
      </div>
    </div>
  )
}

export { PrevArrow, NextArrow, Arrow }
