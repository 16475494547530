import Decimal from 'decimal.js'
import { useMemo } from 'react'

export const normalizeStorefrontProduct = (item, result, coupons, data) => {
  const {
    handle,
    sku,
    name,
    note,
    imgUrl,
    mobileImgUrl,
    label,
    price,
    endTime,
    startTime,
    giftImage,
  } = item || {}

  const product = result?.products?.find(
    (p) => p?.handle?.toUpperCase() === handle?.toUpperCase()
  )
  if (!product) return null
  let variant = product.variants?.find(
    (product) => product?.sku.toUpperCase() === sku?.toUpperCase()
  )
  if (!variant) {
    variant = product.variants?.[0] || {}
  }
  // 来自接口
  let discount = {}
  let couponsData = coupons?.[variant?.sku] || coupons?.[product?.handle] || []
  if (couponsData.length > 0) {
    discount = couponsData?.find((i) => i?.handle === handle) || {}
  } else if (coupons?.length > 0) {
    discount =
      coupons?.find((i) => i?.sku === variant?.sku && i?.handle === handle) ||
      {}
  }

  if (item?.variant_price4wscode) discount = item
  // const discountValue = new Decimal(variant?.listPrice || 0)
  //   ?.minus(variant.price)
  //   ?.toFixed(0, Decimal.ROUND_DOWN)
  return {
    ...item,
    label: label || product?.label || '',
    image: imgUrl || product?.imgUrl || variant?.image?.url,
    mobileImage:
      mobileImgUrl || product?.mobileImgUrl || imgUrl || variant?.image?.url,
    title:
      name ||
      (data?.nameSorce === 'variant' && variant?.metafields?.infos?.name) ||
      product.name,
    note: note,
    handle: product?.handle?.toLowerCase(),
    id: product.id,
    variant: variant,
    sku: variant.sku,
    metafields: product.metafields,
    variantMetafields: variant?.metafields,
    availableForSale: variant.availableForSale,
    basePrice:
      discount?.variant_price4wscode || !variant.listPrice
        ? variant.price
        : variant.listPrice,
    price: discount?.variant_price4wscode ?? variant.price,
    originPrice: variant.price,
    fakePrice: data?.warmUp && price,
    discountType: discount?.value_type || 'fixed_amount',
    currencyCode: product.price?.currencyCode,
    discountValue:
      Math.abs(discount?.value) ||
      (variant?.listPrice &&
        new Decimal(variant.listPrice)
          .minus(variant.price)
          .toFixed(0, Decimal.ROUND_DOWN)),
    code: discount?.title,
    variantId: variant.id,
    variantName: variant?.name,
    brand: product.vendor,
    category: product.productType,
    tags: product.tags,
    variantTags: variant.tags,
    options: variant?.options,
    quantityAvailable: variant?.quantityAvailable,
    startTime: startTime || discount?.starts_at || '',
    endTime: endTime || discount?.ends_at || '',
    freeGift: variant?.metafields?.freeGift || '',
    tradeGift: variant?.metafields?.tradeGift || item?.tradeGift || '',
    giftImage: variant?.metafields?.infos?.gift_image || giftImage || '',
    variantNote: variant?.metafields?.infos?.variantNote,
    images: product?.images,
  }
}
